import React from 'react';
import { NavLink } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";


const Company = ({function1}) => {
  function hideSideBar(){
    function1()
  }
  return (
    <div className="fixed top-0 right-0 h-full w-80 bg-white shadow-lg p-6 overflow-y-auto transition-transform duration-300 transform translate-x-0 z-50">
      {/* <div style={{ textAlign: 'right' }}>
      <button onClick={hideSideBar} className="font-semibold text-black hover:text-blue-400"><img src='close_icon.svg' alt='Close' width="10" height="10"/> </button>
      </div> */}
      <div className="flex justify-end">
            <IoIosClose className='text-[20px] cursor-pointer' onClick={hideSideBar}/>
            </div>
            <div className="flex justify-center">
                <img src="logo.png" alt="Logo" className="w-12 h-12" /> 
                      </div>
      {/* <h3 className="text-xl font-semibold text-center mb-3">Aerosearch Technologies Private Limited</h3> */}
      <div style={{ borderBottom: "1px solid #000 ", marginLeft: 0, marginRight: 0 }}>
      <p className="block text-sm font-medium text-justify py-2 text-gray-700"><b>Aerosearch Technologies Private Limited </b>is a leading provider of drone solutions, AI-powered data analytics, digital transformation services, Drone-as-a-Service (DaaS), and Drone Education. We enable businesses, defence sectors, and educational institutions to optimise operations and improve efficiency.</p>
      {/* <br></br> */}
      </div>

      <div style={{ borderBottom: "1px solid #000 ", marginLeft: 0, marginRight: 0 }}>
      <p className="text-m text-center font-semibold py-1">Vision</p>
      <p className="block text-sm font-medium text-justify py-1 text-gray-700">“To be a global leader in automation and AI-driven drone technologies, revolutionizing industries and defense with innovative, sustainable, and efficient solutions while fostering indigenous innovation.”</p>
        </div>

        <div style={{ borderBottom: "1px solid #000 ", marginLeft: 0, marginRight: 0 }}>
      <p className="text-m text-center font-semibold py-1">Mission</p>
      <p className="block text-sm font-medium text-justify py-1 text-gray-700">“At Aerosearch Technologies, we innovate drone and AI-driven solutions to empower industries and defense with efficiency and sustainability. Committed to Atmanirbhar Bharat, we foster indigenous capabilities and entrepreneurship for a transformative future.”</p>
        </div>

      <div style={{ borderBottom: "1px solid #000 ", marginLeft: 0, marginRight: 0 }}>
        <p className="text-m text-center font-semibold py-1">Why Choose Us?</p>
        <ul style={{listStyleType:"square", marginLeft: 20}}>
          <li>Seamless Integration</li>
          <li>Rapid Deployment</li>
          <li>Zero Capex</li>
          <li>Immediate Results</li>
        </ul>
      </div>

      <p className="mt-4 text-xs text-gray-500">Ready to transform your operations with drones? Write us at: <b style={{color:'black'}}>aerosearchtech@gmail.com </b></p>
    </div>
  );
};

export default Company;